const GEN_SHARELINK_WINDOW_JS_URI = 'scripts/sharelink_window_4ai.15f90974.js';
const GEN_PWA_INSALL_JS_URI = 'scripts/pwa_install_4ai.8bd95f63.js';
const GEN_ONE_SUBMIT_JS_URI = 'scripts/one_submit_4ai.ea419889.js';
const GEN_CTA_CLICK_PHONE_JS_URI = 'scripts/cta_click_phone.c5144da0.js';
const GEN_SLIDER_JS_URI = 'scripts/slider.025d0c11.js';

const themePath = "/voy_content/themes/bati-armor/dist/";

window.collections = {};

const SCRIPTS = [
    {
        'url': GEN_SHARELINK_WINDOW_JS_URI,
        'sel': '.share-links a',
        'col': 'sharelinks'
    },
    {
        'url': GEN_PWA_INSALL_JS_URI,
        'sel': '.pwa-install-btn',
        'col': 'addBtn'
    },
    {
        'url': GEN_ONE_SUBMIT_JS_URI,
        'sel': 'form',
        'col': 'forms'
    },
    {
        'url': GEN_CTA_CLICK_PHONE_JS_URI,
        'sel': 'a[href^="tel:"]',
        'col': 'clickPhone'
    },
    {
        'url': GEN_SLIDER_JS_URI,
        'sel': '[class*="slider"], template',
        'col': 'Slider'
    }
];

let selectors = [];
for (let script of SCRIPTS) {
    selectors.push(script.sel);
}
const obj_present = Array.from(document.querySelectorAll(selectors.join(',')));

for (let script of SCRIPTS) {
    if (Array.from) {
        collections[script.col] = Array.from(document.querySelectorAll(script.sel));
        if (collections[script.col].length > 0) {
            if (script.lazy) {
                let encours = false,
                    observer = new IntersectionObserver(function (entries) {
                        for (let j = 0, lg = entries.length; j < lg; j++) {
                            if (entries[j].intersectionRatio <= 0 || encours) return;
                            encours = true;
                            new loadExt([themePath + script.url]);
                            for (let k = 0, lgt = entries.length; k < lgt; k++) {
                                observer.unobserve(entries[k].target);
                            }
                        }
                    }, obsOptions);

                for (let i = 0, l = collections[script.col].length; i < l; i++) {
                    observer.observe(collections[script.col][i]);
                }

            } else {
                new loadExt([themePath + script.url]);
            }
        }
    }
}

// FONCTIONS JS BATI-ARMOR
(function () {
    // Button on contact panel IN panel

    const contactPanel = document.querySelector('aside.aside-contact-panel .aside-container');
    const contactPanelLabels = document.querySelectorAll('label[for*="contact-panel-"]');
    if (contactPanelLabels != null && typeof (contactPanelLabels) != "undefined" && contactPanel != null && typeof (contactPanel) != "undefined") {
        
        document.addEventListener('click', function (e) {
            if (e.target && (e.target.className.includes('contact-trigger-'))) {
                var contactPanelStyle = getComputedStyle(contactPanel);
                // Try to open pannel contact (1-2-3) before pannel contact (list)
                if (contactPanelStyle.opacity == 0) {
                    document.getElementById('contact-panel').checked = true;
                }
            }
        });
    
        // open popup on hash presence
        var params = new URLSearchParams(window.location.hash);
        if (params.has('#contact') || params.has('#rappel') || params.has('#message') || params.has('#rdv')) {
            document.getElementById('contact-panel').checked = true;
            switch (window.location.hash) {
                case '#rappel':
                    var rappelPanel = document.getElementById('contact-panel-1');
                    if(rappelPanel != null && typeof (rappelPanel) != "undefined"){
                        rappelPanel.checked = true;
                    }
                    break;
                case '#message':
                    var messagePanel = document.getElementById('contact-panel-2');
                    if(messagePanel != null && typeof (messagePanel) != "undefined"){
                        messagePanel.checked = true;
                    }
                    break;
                case '#rdv':
                    var rdvPanel = document.getElementById('contact-panel-3');
                    if(rdvPanel != null && typeof (rdvPanel) != "undefined"){
                        rdvPanel.checked = true;
                    }
                    break;
            }
        } 
    }

    // CHECK if safari browser
    var isIos = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isIos) {
        document.body.classList.add('safari');
    }

    // Form Search url
    var searchDepartementInput = document.querySelector('[name*="departement"]');
    var searchVilleInput = document.querySelector('[name*="ville"]');
    if (searchVilleInput != null) {
        var searchDataListVille = searchVilleInput.nextElementSibling;
    }
    if (searchDepartementInput != null) {
        var searchArchive = searchDepartementInput.closest('form');
        var searchDataListDepartement = searchDepartementInput.nextElementSibling;
        if (searchArchive != null && searchDataListDepartement != null) {
            // On submit searchbox operation
            // --> change url action with projet / eligibilite / type_bien / departement / ville (seo)
            // --> put geoloc points (from api) in form (result not empty) 
            searchArchive.addEventListener('submit', function (e) {
                //e.preventDefault();

                var action_url = searchArchive.getAttribute('action');
                var is_immobilier = false;

                // replace all flash at the end (^ for begin) with oinly 1 slash
                // str = str.replace(/\/+$/g, '/');

                // projet
                var projet = searchArchive.querySelector('[name="projet"]');
                if (projet !== null) {
                    var projetV = projet.value;
                    if (projetV != "") {
                        // Immobilier entreprise
                        if (projetV == "immobilier-neuf-entreprise") {
                            is_immobilier = true;
                        }
                        action_url = '/' + projetV + '/';
                    }
                }

                // eligibilite OU type_bien
                if (!is_immobilier) {
                    var eligibilite = searchArchive.querySelector('[name="eligibilite"]');
                    if (eligibilite !== null) {
                        var eligibiliteV = eligibilite.value;
                        if (eligibiliteV != "") {
                            action_url += eligibiliteV + '/';
                        }
                    }
                } else {
                    var type_bien = searchArchive.querySelector('[name="type_bien"]');
                    if (type_bien !== null) {
                        var type_bienV = type_bien.value;
                        if (type_bienV != "") {
                            action_url += type_bienV + '/';
                        }
                    }
                }

                // departement
                var departement = searchArchive.querySelector('[name="departement"]').value;
                if (departement != "") {
                    action_url += sanitizeString(departement) + '/';
                } else {
                    action_url += 'departement/';
                }

                // ville
                var ville = searchArchive.querySelector('[name="ville"]').value;
                if (ville != "") {
                    action_url += sanitizeString(ville) + '/';

                    // Geoloc (pour avoir des résultats a coté de la ville sélectionnée)
                    var inputGeoLat = searchArchive.querySelector('input[name="geo_lat"]');
                    var inputGeoLng = searchArchive.querySelector('input[name="geo_lng"]');

                    //var currentOption = dataList.querySelector('li span[value="' + ville + '"]');
                    var currentOption = Array.from(searchDataListVille.querySelectorAll('li span.datalistville')).find(el => el.textContent === ville);
                    inputGeoLat.value = '';
                    inputGeoLng.value = '';

                    if (typeof currentOption !== 'undefined') {
                        if (currentOption.textContent != "Aucune ville n'existe avec ce nom et le département choisi") {
                            inputGeoLat.value = currentOption.closest('li').getAttribute('data-lat');
                            inputGeoLng.value = currentOption.closest('li').getAttribute('data-lng');
                        }
                    }
                }

                // return 
                searchArchive.setAttribute('action', action_url);
            });

            // On click option dataList
            // --> change value in input searchbox
            // --> close ul datalist
            // --> display: none other choice in datalist options
            document.addEventListener('click', function (e) {
                // On click option dataList
                if (e.target && (e.target.className == 'datalistcp' || e.target.className == 'datalistville' || e.target.className == 'datalistdepartement' || e.target.className == 'datalistoption')) {
                    var currentType = e.target.closest('ul').previousElementSibling;
                    var currentOption = e.target.closest('li');

                    // Input Departement || Ville
                    if(currentType.getAttribute('name') === 'departement') {
                        searchDepartementInput.value = currentOption.querySelector('span.datalistdepartement').textContent;
                        searchVilleInput.value = '';
                        searchDataListDepartement.classList.remove('active');

                        // Simulate keyup for trigger listener in "ajax.js" to show/hide element in dataList
                        searchDepartementInput.dispatchEvent(new KeyboardEvent('keyup', {'key': 'Space'}));
                    } else {
                        searchVilleInput.value = currentOption.querySelector('span.datalistville').textContent;
                        searchDepartementInput.value = currentOption.querySelector('span.datalistdepartement').textContent.split(" (")[0];
                        searchDataListVille.classList.remove('active');
                        
                        // Simulate keyup for trigger listener in "ajax.js" for show/hide element in dataList
                        searchDepartementInput.dispatchEvent(new KeyboardEvent('keyup', {'key': 'Space'}));
                    }
                }
            });

            // On focus input searchbox
            // --> hide datalist
            searchDepartementInput.addEventListener('focus', function (e) {
                searchDataListDepartement.classList.add('active');
            });
            searchVilleInput.addEventListener('focus', function (e) {
                searchDataListVille.classList.add('active');
            });

            // On blur input searchbox
            // --> hide datalist
            searchDepartementInput.addEventListener('blur', function (e) {
                if (e.relatedTarget != null && e.relatedTarget.classList.contains('datalistoption')) {
                    setTimeout(() => {
                        searchDataListDepartement.classList.remove('active');
                    }, 200)
                } else {
                    searchDataListDepartement.classList.remove('active');
                }
            });

            searchVilleInput.addEventListener('blur', function (e) {
                if (e.relatedTarget != null && e.relatedTarget.classList.contains('datalistoption')) {
                    setTimeout(() => {
                        searchDataListVille.classList.remove('active');
                    }, 200)
                } else {
                    searchDataListVille.classList.remove('active');
                }
            });

        }

    }

    // popup
    let linkPopin = document.querySelectorAll('[class*="popin-"]');
    if (typeof (linkPopin) != "undefined") {
        for (var i = 0; i < linkPopin.length; i++) {
            linkPopin[i].addEventListener('click', function (e) {
                e.preventDefault();
                var idTemplateArr = this.className.split("-");
                const instance = basicLightbox.create(
                    document.querySelector('template#' + idTemplateArr[1])
                    , {
                        onShow: (instance) => {
                            setTimeout(() => {
                                const sliderGalerie = instance.element().querySelectorAll('.slider-galerie');
                                if (sliderGalerie.length > 0) {
                                    sliderGalerie.forEach(function (el) {
                                        SliderMethods.InitNav(el);
                                    });
                                }
                            }, 1000);

                            instance.element().querySelector('.modal-close').onclick = instance.close;
                        }
                    });
                instance.show();
            });
        }
    }

    // toggle password view
    const togglePasswords = document.querySelectorAll('input[type="password"] + button.visible-password');
    if (togglePasswords != null && typeof (togglePasswords) != "undefined") {
        togglePasswords.forEach(function (element, id) {
            element.addEventListener('click', function (e) {
                // toggle the type attribute
                const password = element.previousElementSibling;
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);
            });
        });
    }

    // Orderby form
    const orderSelect = document.querySelector('select[name="orderby"]');
    if (orderSelect != null && typeof (orderSelect) != "undefined") {
        const formPrograms = document.querySelector('form#filters-archive-programmes');
        if (formPrograms != null && typeof (formPrograms) != "undefined") {
            orderSelect.addEventListener('change', function (e) {
                formPrograms.querySelector('input[name="orderby"]').value = e.target.value;
                formPrograms.submit();
            });
        }
    }

    function sanitizeString(str) {
        var r = str.trim().toLowerCase();
        r = r.replace(new RegExp(/\s/g), "-");
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        r = r.replace(new RegExp(/\W/g), "-");
        return r;
    }


}) ();
